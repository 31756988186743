// NttData.tsx
import React, { FC, useEffect, useState } from 'react';
import nttHero from './../assets/ntt/ntt-hero.png';
import nttDocument from './../assets/ntt/ntt-document.png';
import nttWireframe01 from './../assets/ntt/home-wireframe-01.png';
import nttWireframe02 from './../assets/ntt/home-wireframe-02.png';
import nttWireframe03 from './../assets/ntt/initiative-search-wireframe-01.png';
import nttWireframe04 from './../assets/ntt/initiative-search-wireframe-02.png';
import nttNavBefore from './../assets/ntt/ntt-nav-before.png';
import nttNavAfter from './../assets/ntt/ntt-nav-after.png';
import before01 from './../assets/ntt/before01.png';
import before02 from './../assets/ntt/before02.png';
import after01 from './../assets/ntt/after01.png';
import after02 from './../assets/ntt/after02.png';
import nextStep from './../assets/ntt/next-step.png';
import mockup01 from './../assets/ntt/mockup01.png';
import mockup02 from './../assets/ntt/mockup02.png';
import { Chip } from '@mui/material';
import { Parallax } from 'react-scroll-parallax';
import ProjectButton from './NextProject';


interface NttDataProps {
  // Add your properties here
}
const NttData: FC<NttDataProps> = (props) => {
  const chips = [
    { label: 'Web App' },
    { label: 'UX Design' },
    { label: 'Front-end Development' },
  ];

  const nextProjectLink = '/project-3';
  const prevProjectLink = '/project-4';

  const [parallaxSpeed, setParallaxSpeed] = useState(50);

  useEffect(() => {
    const handleResize = () => {
      const newSpeed = window.innerWidth < 601 ? 10 : 50; // Adjust the speed as needed
      setParallaxSpeed(newSpeed);
    };

    // Initial setup
    handleResize();

    // Event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  console.log(parallaxSpeed);

  return (
    <div className="projects">
      <div className='ntt-hero main'>
        <div className='parallax-container'>
          <Parallax speed={-20}>
            <img src={nttHero} className="circle-back" />
          </Parallax>
        </div>
      </div>
      <div className="hero-text">
        <h3>NTT DATA</h3>
        <h2>Elevating Initiative Management</h2>
        <p>Web Application Redesign project to support the institute’s scientific, administrative, and budgetary processes related to initiative planning, development and execution</p>
        {chips && (
          <div className="chip-container">
            {chips.map((chip, index) => (
              <Chip label={chip.label} variant="outlined" key={index} />
            ))}
          </div>
        )}
      </div>

      <div className='role-container text-w'>
        <div className='role-contents'>
          <h4>
            Role
          </h4>
          <p>
            UX Designer: <br />Led end-to-end process covering discovery, design, usability testing, and front-end development.
          </p>
        </div>
        <div className='role-contents'>
          <h4>
            Team
          </h4>
          <p>
            Senior Development Manager<br />
            Product Manager<br />
            Developers<br />
            Business Analyst</p>
        </div>
        <div className='role-contents'>
          <h4>
            Platform
          </h4>
          <p>
            Web Application</p>
        </div>
      </div>
      <div className='text-container text-w'>
        <h2>
          Overview
        </h2>
        <p>
          As a UX designer in a renowned research institute, I spearheaded a redesign project to enhance initiative management. Our primary objective was to refine the existing system, ensuring a smooth journey from adding new initiative descriptors to concept clearance. This integrated solution, accessible via the institute's intranet, aimed to streamline scientific, administrative, and budgetary processes.
        </p>
      </div>
      <div className='mockup blue-bg'>
        <Parallax
          easing="easeInQuad"
          speed={parallaxSpeed}>
          <img src={mockup01} />
        </Parallax>

      </div>


      <div className='text-container text-w'>
        <h2>
          Journey for Design Process
        </h2>
        <p>
          The solution team demonstrated a commitment to user-centricity by conducting in-depth research and analysis, culminating in a comprehensive over 100 pages redesign requirements documentation that they handed over to me.
        </p>
        <img src={nttDocument} />
        <p>
          I dedicated time to thoroughly understand and learn, utilizing the provided documentation to guide and shape the design direction.
        </p>
        <p>
          I began by generating rapid sketches in Adobe XD, experimenting with diverse component styles and layouts to establish a foundational model. This approach enabled me to identify preferred features from each sketch and determine their compatibility, both among themselves and with the existing format.        </p>
        <p>
          This is the simplified version of original wireframes I created.         </p>
        <p className='annotation'>Home Dashboard Wireframes</p>
        <div className='wireframe-container'>
          <img src={nttWireframe01} />
          <img src={nttWireframe02} />
        </div>
        <p className='annotation'>Initiative Search/List Wireframes</p>

        <div className='wireframe-container'>
          <img src={nttWireframe03} />
          <img src={nttWireframe04} />
        </div>
      </div>

      <div className='text-container text-w'>
        <h2>
          Iterative Design Collaboration
        </h2>
        <p>
          After creating the initial wireframes, I share them with the internal team, consisting of project managers, business analysts, and developers. We conduct weekly meetings to collect feedback and insights, refining the wireframes based on the discussions.
        </p>
      </div>
      <div className='mockup green-bg'>
        <Parallax speed={parallaxSpeed}>
          <img src={mockup02} />
        </Parallax>
      </div>
      <div className='text-container text-w'>
        <p>
          After refining the initial wireframe based on feedback from our team, the next crucial step is to present the updated design to stakeholders. This represents a pivotal moment to gather user feedback on the initial wireframe, ensuring everyone agrees on the redesigned layout and overall appearance. This collaborative checkpoint is essential before progressing to the development of subsequent wireframes based on these initial design refinements.        </p>
        <h2>
          Redesign and Implemented Solutions
        </h2>
        <p>During the redesign, I brainstormed ideas to enhance the system, focusing on specific issues to improve the overall user experience. By implementing these solutions, we aim to create a more user-friendly system that aligns with our redesign goals.</p>
        <p>I kicked off the redesign by restructuring the entire site, removing unnecessary pages, and strategically relocating certain functions to more appropriate sections. This resulted in a reduction of main-level navigation pages, providing users with a more streamlined and less confusing experience.</p>
        <p className='annotation'>Before Navigation Bar</p>
        <img src={nttNavBefore} className="mb-4" />

        <p className='annotation'>After Navigation Bar</p>
        <img src={nttNavAfter} />
        <p>Below are some implemented solutions from the redesign phase to address specific issues and enhance the overall user experience.</p>
      </div>
      <div className="before-after">
        <div className='ntt-hero grey-bg'>
          <p className='annotation'>Before Phasing Action/View</p>
          <img src={before01} />
        </div>
        <div className='ntt-hero white-bg'>
          <p className='annotation'>After Phasing Action/View</p>
          <img src={after01} />
        </div>
      </div>
      <div className="before-after">
        <div className='ntt-hero grey-bg'>
          <p className='annotation'>Before Email Subscription Page</p>
          <img src={before02} />
        </div>
        <div className='ntt-hero white-bg'>
          <p className='annotation'>After Email Subscription Page</p>
          <img src={after02} />
        </div>
      </div>
      <div className='text-container text-w'>
        <h2>
          Next Steps
        </h2>
        <p>
          After thorough collaboration and feedback from the working group and stakeholders, we successfully obtained final approval for the wireframes. It was crucial to ensure alignment and satisfaction with the proposed UX changes among all parties involved.
        </p>
        <p>In a close partnership with the Software Development Senior Manager, I am actively engaged in crafting UI components for this ongoing project. As we are still in progress, continuous efforts are being made to bring the envisioned user experience to life.</p>
      </div>
      {/* <img src={nextStep} className="w-100" /> */}
      <div className="project-button">
        <div className="container">
          <ProjectButton link={prevProjectLink} isNext={false} />
          <ProjectButton link={nextProjectLink} isNext={true} />
        </div>
      </div>
    </div>
  );
};

export default NttData;
