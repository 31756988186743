import React from 'react';
import { useForm, ValidationError } from '@formspree/react';

const Contact = () => {
    const emailAddress = 'kirakim0306@gmail.com';
    function ContactForm() {
        const [state, handleSubmit] = useForm("xgejgaap");
        if (state.succeeded) {
            return <p>Thanks for reaching out! I'll get back to you as soon as possible. <br />
                Feel free to connect with me <a className="emoji" href="https://www.linkedin.com/in/kira-kim/" target="_blank">😎</a> or my<a className="emoji" href="https://www.instagram.com/sbmochi/" target="_blank" >🐶</a></p>
        }
        return (
            <form className="contact-form" onSubmit={handleSubmit}>
                <input
                    className="input"
                    id="name"
                    placeholder="Your name"
                    type="text"
                    required
                />
                <input
                    placeholder="Your email address"
                    id="email"
                    className="input"
                    type="email"
                    name="email"
                    required
                />
                <ValidationError
                    prefix="Email"
                    field="email"
                    errors={state.errors}
                />
                <textarea
                    placeholder="Message"
                    className="input"
                    id="message"
                    name="message"
                    style={{ height: '150px' }}
                    required
                />
                <ValidationError
                    prefix="Message"
                    field="message"
                    errors={state.errors}
                />
                <button type="submit" disabled={state.submitting}>
                    Submit
                </button>

            </form>
        );
    }

    const handleEmailClick = () => {
        window.location.href = `mailto:${emailAddress}`;
    };

    return (
        <div>
            <h2>Get in touch <span role="img" aria-label="Coffee" className="coffee-emoji">
        ☕
    </span></h2>
            <ContactForm />

        </div>

    );
};

export default Contact;