import React from 'react';
import { Link } from 'react-router-dom';

interface ProjectButtonProps {
  link: string;
  isNext: boolean;
}

const ProjectButton: React.FC<ProjectButtonProps> = ({ link, isNext }) => {
  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const arrowDirection = isNext ? '→' : '←';

  return (
      <Link to={link} onClick={handleClick}>
        {isNext ? <button className="next">Next Project</button> : <button className="previous">Previous Project</button>

    }
      </Link>
  );
};

export default ProjectButton;
