import React, { useState } from 'react';
import Link from '@mui/joy/Link';
import { Nav } from 'react-bootstrap';
import Logo from './Logo';
import Download from './../../assets/images/download.svg';
const Header: React.FC = () => {

  return (
    <header className="header">
      <div className="top-nav">
        <a href="/" className='home nav'>
          Kira Kim
        </a>
        <Nav>
          <Link href="/#work">Works</Link>
          <Link href="/archive">Archive</Link>
          <Link href="/files/KiraKimResume.pdf" target="_blank" download >Resume</Link>
          <Link href="/#contact">Contact</Link>
        </Nav>
      </div>
    </header>
  );
}

export default Header;
