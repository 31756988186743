import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ca01 from './../assets/evme/competitive-analysis-01.png';
import ca02 from './../assets/evme/competitive-analysis-02.png';
import cardSort01 from './../assets/evme/cardsort01.png';
import cardSort02 from './../assets/evme/cardsort02.png';
import cardSort03 from './../assets/evme/cardsort03.png';
import userSurvey01 from './../assets/evme/user-survey01.png';
import userSurvey02 from './../assets/evme/user-survey02.png';
import userPersona from './../assets/evme/evme-persona.png';
import journeyMap from './../assets/evme/evme-journey-map.png';

interface CollapsibleWidgetProps {
    // content?: React.ReactNode;
}

export default function CollapsibleWidget(props: CollapsibleWidgetProps) {
    return (
        <div>
            <Accordion className="collapsible-container">
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <h3>Competitive Analysis</h3>
                </AccordionSummary>
                <AccordionDetails>
                    <p>
                        Competitive analysis provides insights into the electric vehicle market to improve features and enhance user experience. My emphasis was on identifying the improvements customers desire based on competitors' offerings.
                        <img src={ca01} />
                        <img src={ca02} />
                    </p>
                </AccordionDetails>
            </Accordion>
            <Accordion className="collapsible-container">
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <h3>Card Sorting</h3>
                </AccordionSummary>
                <AccordionDetails>
                    <p>
                        Card sorting for the EVME App is essential to define its information architecture, shaping the app's structure and user flow. I utilized the hybrid card sorting method via Optimal Sort, an online platform, for effective and collective data gathering.
                    </p>
                    <img src={cardSort01} />
                    <img src={cardSort02} />
                    <img src={cardSort03} />

                </AccordionDetails>
            </Accordion>
            <Accordion className="collapsible-container">
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3a-content"
                    id="panel3a-header"
                >
                    <h3>User Survey</h3>
                </AccordionSummary>
                <AccordionDetails>
                    <p>
                        I used surveys to collect both qualitative and quantitative data efficiently. By incorporating screener questions at the beginning of the Google Form, I ensured the main survey was visible only to the right target audience. This approach not only saved time but also facilitated effective and prompt feedback.                    </p>
                    <img src={userSurvey01} />
                    <img src={userSurvey02} />

                </AccordionDetails>
            </Accordion>
            <Accordion className="collapsible-container">
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3a-content"
                    id="panel3a-header"
                >
                    <h3>User Persona</h3>
                </AccordionSummary>
                <AccordionDetails>
                    <p>
                        Creating user personas helped us understand diverse user needs and tailor the EVME App to ensure a personalized and user-friendly experience.</p>
                    <img src={userPersona} />
                </AccordionDetails>
            </Accordion>
            <Accordion className="collapsible-container">
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3a-content"
                    id="panel3a-header"
                >
                    <h3>Journey Mapping</h3>
                </AccordionSummary>
                <AccordionDetails>
                    <p>
                        Journey mapping allowed us to visualize the user's interaction with the EVME App, from the initial touchpoints to task completion. This comprehensive overview helped identify pain points and optimize the user journey for a smoother experience.
                    </p>
                    <img src={journeyMap} />
                </AccordionDetails>
            </Accordion>
        </div>
    );
}