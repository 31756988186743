// MobileHeader.tsx
import * as React from 'react';
import Box from '@mui/joy/Box';
import IconButton from '@mui/joy/IconButton';
import Drawer from '@mui/joy/Drawer';
import List from '@mui/joy/List';
import Typography from '@mui/joy/Typography';
import ModalClose from '@mui/joy/ModalClose';
import Menu from '@mui/icons-material/Menu';
import Link from '@mui/joy/Link';
import _debounce from 'lodash/debounce';


export default function MobileHeader() {
    const [open, setOpen] = React.useState(false);
    const [isWhiteHamburger, setIsWhiteHamburger] = React.useState(true);

    const handleLinkClick = (href: string) => {
        setOpen(false); // Close the drawer before navigating
        // Add any additional logic for handling the click event if needed
    };

    React.useEffect(() => {
        const handleScroll = () => {
            // const debouncedHandleScroll =  _debounce(handleScroll, 100); // 100 milliseconds debounce delay

            const heroElement = document.querySelector('.hero');
            // const projectHeaderElement = document.querySelector('.project-header');


            if (heroElement) {
                const heroBottom = heroElement.getBoundingClientRect().bottom;
                const isWhite = window.screenTop < heroBottom;


                setIsWhiteHamburger(isWhite);
            // } else if (projectHeaderElement) {
            //     // If there is a .project-header element on the page, check its position
            //     const projectHeaderBottom = projectHeaderElement.getBoundingClientRect().bottom;
            //     const isWhite = window.screenTop < projectHeaderBottom;

            //     setIsWhiteHamburger(isWhite);
            } else {
                // If there is no hero element on the page, ensure white-hamburger is false
                setIsWhiteHamburger(false);
            }
        };


        // Check and update the hamburger color on mount and scroll
        handleScroll();
        window.addEventListener('scroll', handleScroll);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <React.Fragment>
            <div className={`mobile-header${isWhiteHamburger ? ' white-hamburger' : ''}`}>
                <div className="top-nav">
                    <IconButton onClick={() => setOpen(true)}>
                        <Menu />
                    </IconButton>
                    <Drawer open={open} anchor='right' onClose={() => setOpen(false)}>
                        <Box
                            className="close-btn"
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 0.5,
                                ml: 'auto',
                                mt: 1,
                                mr: 2,
                            }}
                        >
                            <Typography
                                component="label"
                                htmlFor="close-icon"
                                fontSize="sm"
                                fontWeight="lg"
                                sx={{ cursor: 'pointer' }}
                            >
                                Close
                            </Typography>
                            <ModalClose id="close-icon" sx={{ position: 'initial' }} />
                        </Box>
                        <div className="mobile-menu-container">
                            <List
                                className="mobile-menu"
                                size="lg"
                                component="nav"
                                sx={{
                                    flex: 'none',
                                    fontSize: 'xl',
                                    '& > div': { justifyContent: 'center' },
                                }}
                            >
                                <Link href="/" onClick={() => handleLinkClick('/')}>home</Link>
                                <Link href="/#work" onClick={() => handleLinkClick('/#work')}>works</Link>
                                <Link href="/archive" onClick={() => handleLinkClick('/archive')}>archive</Link>
                                <Link href="/files/KiraKimResume.pdf" target="_blank" download onClick={() => handleLinkClick('/files/KiraKimResume.pdf')}>resume</Link>
                                <Link href="/#contact" onClick={() => handleLinkClick('/#contact')}>contact</Link>
                            </List>
                        </div>
                    </Drawer>
                </div>
            </div>
        </React.Fragment>
    );
}
