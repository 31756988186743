// Layout.tsx
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Archives from './Projects/Archive';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import EVMECaseStudy from './Projects/Components/EVMECaseStudy';
import Footer from './Footer/Footer';
import Home from './Home';
import Header from './Header/Header';
import ScrollToAnchor from './ScrollToAnchor';
import MobileHeader from './Header/MobileHeader';
import WebDesign from './Projects/Components/WebDesign';
import NttData from './Projects/Components/NttData';
import EVME from './Projects/Components/EVME';
// import NIAID from './Projects/Components/NIAID';
// import GroundFi from './Projects/Components/GroundFi';

interface LayoutProps { }

const Layout: React.FC<LayoutProps> = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [showScrollToTop, setShowScrollToTop] = useState(false);

    const handleScrollToTop = () => {

        window.scrollTo({ top: 0, behavior: 'smooth' });

    };
    const handleScroll = () => {
        const scrollThreshold = 200; // Adjust this threshold as needed

        // Check if the user has scrolled past the threshold
        const shouldShowScrollToTop = window.scrollY > scrollThreshold;

        setShowScrollToTop(shouldShowScrollToTop);
    };
    const navigationItems = [
        { label: 'Home', to: '/' },
        { label: 'Works', to: '/#work' },
        { label: 'Archive', to: '/archive' },
        { label: 'Resume', to: '../public/files/KiraKimResume.pdf' },
        { label: 'Contact', to: '/#contact' },
    ];

    useEffect(() => {
        // Move the overlay initialization here

        const setBodyContainerHeight = () => {
            const wrapper = document.querySelector('.wrapper') as HTMLElement;
            const footer = document.querySelector('footer') as HTMLElement;

            if (wrapper && footer) {
                const footerHeight = footer.offsetHeight;
                const windowHeight = window.innerHeight;

                wrapper.style.minHeight = `${windowHeight - footerHeight}px`;
            }
        };

        const handleResize = () => {
            setBodyContainerHeight();
            setIsMobile(window.innerWidth <= 768);
        };

        setBodyContainerHeight();

        window.addEventListener('resize', handleResize);
        window.addEventListener('scroll', handleScroll); // Add scroll event listener


        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('scroll', handleScroll);

        };
    }, []);
    const isHomePage = window.location.pathname === '/';

    return (
        <Router>
            <div className="wrapper">
                <ScrollToAnchor />
                {isMobile ? <MobileHeader /> : <Header />}

                <div className="body-container">
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/archive" element={<Archives />} />
                        {/* <Route path="/project-1" element={<NIAID />} /> */}
                        <Route path="/project-1" element={<NttData />} />
                        {/* <Route path="/project-2" element={<EVME />} /> */}
                        <Route path="/project-3" element={<EVME />} />
                        <Route path="/project-4" element={<WebDesign />} />
                    </Routes>
                </div>
                {showScrollToTop && (
                    <button className="scroll-to-top" onClick={handleScrollToTop}>
                        <ArrowUpwardIcon />
                    </button>
                )}

                <Footer items={navigationItems} />
            </div>
        </Router>
    );
};

export default Layout;
