import React from 'react';
import './App.scss';
import Layout from './components/Layout';

const App: React.FC = () => {
  return (

    <div className="App">
          <Layout />
    </div> 
  );
}

export default App;
