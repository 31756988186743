import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import PetsIcon from '@mui/icons-material/Pets';
import Link from '@mui/material/Link';

interface FooterProps {
  items: { label: string; to: string }[];
}

const Footer: React.FC<FooterProps> = ({ items }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className="footer">
      <section className="logo"></section>
      <section className="footer-nav">
        <div className="d-flex justify-content-center">
          <p className="copyright">©2024 Kira Kim</p>
          <div className="sitemap">
            {items.map((item, index) => (
              <React.Fragment key={index}>
                {item.label === 'Resume' ? (
            <Link href="/files/KiraKimResume.pdf" target="_blank" download>Resume</Link>
            ) : (
                  <RouterLink onClick={handleClick} to={item.to}>
                    {item.label}
                  </RouterLink>
                )}
                {index < items.length - 1 && <span> | </span>}
              </React.Fragment>
            ))}
          </div>
          <div className="social">
                <a href="https://www.linkedin.com/in/kira-kim/" target="_blank" > <LinkedInIcon /></a>
                <a href="https://www.instagram.com/sbmochi/" target="_blank" > <PetsIcon /></a>
                <a href="https://www.facebook.com/kira0306" target="_blank" > <FacebookIcon /></a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Footer;
