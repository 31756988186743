// Home.tsx
import React from "react";
import Hero from './Hero/Hero';
import Projects from './Projects/Projects';
import ProjectImg01 from './Projects/assets/niaid-ntt.png';
import ProjectImg02 from './Projects/assets/ground-fi-main.png';
import ProjectImg03 from './Projects/assets/evme.png';
import ProjectImg04 from './Projects/assets/web-design.png';
import Contact from "./Contact";
const Home: React.FC = () => {
  return (
    <>
    <section id="Hero">
        <Hero />
    </section>
    <section id="work" className="project">
        <Projects
            projectId="1"
            title="Elevating Initiative Management"
            subtitle="NTT Data"
            // year="2021-2023"
            backgroundImage={ProjectImg01}
            chips={[
                { label: 'Web App' },
                { label: 'UX Design' },
                { label: 'Front-end Development' },
            ]}
        />
        {/* <Projects
            projectId="2"
            title="Cargo Space Sharing Platform"
            subtitle="GroundFi"
            // year="2021-2023"
            passwordProtected
            backgroundImage={ProjectImg02}
            chips={[
                { label: 'Mobile App' },
                { label: 'Web App' },
                { label: 'Website' },
                { label: 'Wix Web Dev' },
            ]}
        /> */}
        <Projects
            projectId="3"
            title="EV Charging Station Locator App"
            subtitle="EVME"
            // year="2021"
            backgroundImage={ProjectImg03}
            chips={[
                { label: 'Mobile App' },
                { label: 'UX Design' },
                { label: 'Branding' },
            ]}
        />
        <Projects
            projectId="4"
            title="Web Design Projects"
            subtitle="Dreamscape Marketing"
            backgroundImage={ProjectImg04}
            // year="2020-2021"
            chips={[
                { label: 'Website' },
                { label: 'Wordpress' },
                { label: 'Front-end Development' },
            ]}
        />
    </section>
    <section id="contact" className="contact">
        <Contact />
    </section>
</>
  );
};

export default Home;
