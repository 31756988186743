import React, { ReactNode } from 'react';
import Chip from '@mui/material/Chip';
import LockIcon from '@mui/icons-material/Lock';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

interface ProjectsProps {
  backgroundImage?: string; // The background image URL
  projectId: string;
  title: string;
  subtitle: string;
  year?: string;
  chips?: { label: string; startDecorator?: ReactNode }[]; // Array of chips with optional decorator
  passwordProtected?: boolean;
}


const Projects: React.FC<ProjectsProps> = ({ passwordProtected, projectId, title, subtitle, chips, year, backgroundImage }) => {
  const containerStyle: React.CSSProperties = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
  }

  return (
    <div className="project-container" id={`project-${projectId}`}>
      <a href={`/project-${projectId}`} className="project-img" style={{
        backgroundImage: `url(${backgroundImage})`
      }}></a>
      <div className="project-text">
        <h3>{subtitle}
          {year && (<span className="year">| </span>)}
          <span className="year">{year}</span>
          {passwordProtected && 
          <Tooltip placement="top" title="Confidential Projects">
            <IconButton>
              <LockIcon className="lock-icon" />
            </IconButton>
          </Tooltip>}
        </h3>
        <a href={`/project-${projectId}`}><h2>{title}</h2></a>
        {chips && (
          <div className="chip-container">
            {chips.map((chip, index) => (
              <Chip label={chip.label} variant="outlined" />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Projects;
