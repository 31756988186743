import React, { useEffect, useState } from 'react';
import kiraPortrait from '../../assets/images/kira-portrait.png';
import circleBack from '../../assets/images/circle-back.png';
import circleFront from '../../assets/images/circle-front.png';
import circleMiddle from '../../assets/images/circle-middle.png';
import hand from '../../assets/images/hand.png';
import { Parallax } from 'react-scroll-parallax';


const Hero: React.FC = () => {
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const offset = window.innerHeight * 0.8; // Adjust as needed
            const scrolled = window.scrollY > offset;
            setScrolled(scrolled);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className={`hero ${scrolled ? 'scrolled' : ''}`}>
            <div className="container">
                <div className='parallax-container'>
                    <Parallax speed={-10}>
                        <img src={circleBack} className="circle-back" />
                    </Parallax>
                    <Parallax speed={-5}>
                        <img src={circleMiddle} className="circle-middle" />
                    </Parallax>
                    <Parallax speed={-10}>
                        <img src={kiraPortrait} alt="portrait" className="portrait" />
                    </Parallax>
                    <Parallax speed={5}>
                        <img src={circleFront} className="circle-front" />
                    </Parallax>

                </div>
                <div className="hero-text">
                    <h3 className="first-line">
                        Hi! I’m <b>Kira Kim</b><span className="hand-cursor">
                            <img src={hand} alt="Hand Icon" />
                        </span>
                    </h3>
                    <h1>
                        Senior UX Designer/ <br />
                        Front-end Developer <br />
                        <span></span>
                    </h1>
                    <h3>based in DC Metro Area <br className="mobile" /><span className="ntt">Working @NTT DATA</span></h3>
                </div>
            </div>
        </div>
    );
};

export default Hero;
