import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import housingOurHomelessImage from '../../Projects/assets/web-design/kkim-website-portfolio-housingourhomeless.png';
import abaToolsImage from '../../Projects/assets/web-design/kkim-website-portfolio-abatools.png';
import codeMaxImage from '../../Projects/assets/web-design/kkim-website-portfolio-codemax.png';
import compassRecoveryImage from '../../Projects/assets/web-design/kkim-website-portfolio-compassrecovery.png';
import learningBehavioralCenterImage from '../../Projects/assets/web-design/kkim-website-portfolio-learningandbehaviorcenter.png';
import assuraSourceImage from '../../Projects/assets/web-design/kkim-website-portfolio-assurasource.png';
import southworthAssociatesImage from '../../Projects/assets/web-design/kkim-website-portfolio-southworth.png';
import tenyurTalentEngagementImage from '../../Projects/assets/web-design/kkim-website-portfolio-tenyur.png';
import serenoRidgeRecoveryImage from '../../Projects/assets/web-design/kkim-website-portfolio-serenoridge.png';
import virtualTradeShowPlatformImage from '../../Projects/assets/web-design/kkim-website-portfolio-virtualtradeshow.png';
import cottageCareImage from '../../Projects/assets/web-design/cottage-care.png';
import Chip from '@mui/material/Chip';
import ProjectButton from './NextProject';


const WebDesign: React.FC = () => {
    const prevProjectLink = '/project-3';
    const nextProjectLink = '/project-1';
    const projects = [
        { id: 'web-2', link: 'https://www.abatools.com/', title: 'aba tools', backgroundImage: abaToolsImage },
        { id: 'web-3', link: 'https://codemaxmb.com/', title: 'CodeMax', backgroundImage: codeMaxImage },
        { id: 'web-4', link: 'https://www.compassrecoverytreatment.com/', title: 'Compass Recovery', backgroundImage: compassRecoveryImage },
        { id: 'web-5', link: 'https://www.learningandbehavioralcenter.com/', title: 'Learning and Behavioral Center', backgroundImage: learningBehavioralCenterImage },
        { id: 'web-6', link: 'https://www.assurasource.com/', title: 'Assura Source (Coming Soon)', backgroundImage: assuraSourceImage },
        { id: 'web-7', link: 'https://www.southworthassociates.net/', title: 'Southworth Associates', backgroundImage: southworthAssociatesImage },
        { id: 'web-11', link: 'https://www.virtualtradeshowplatform.com/', title: 'Virtual Trade Show Platform', backgroundImage: virtualTradeShowPlatformImage },
        { id: 'web-1', link: 'https://www.housingourhomelessvets.org/', title: 'Housing Our Homeless Vets', backgroundImage: housingOurHomelessImage },
        { id: 'web-8', link: 'none', title: 'Tenyur Talent Engagement', backgroundImage: tenyurTalentEngagementImage, disabled: true },
        { id: 'web-9', link: 'none', title: 'Sereno Ridge Recovery', backgroundImage: serenoRidgeRecoveryImage, disabled: true },
        { id: 'web-12', link: 'none', title: 'Cottage Care', backgroundImage: cottageCareImage, disabled: true },
    ];

    const liveSites = projects.filter(project => project.link !== 'none' && !project.disabled);
    const noLongerAvailable = projects.filter(project => project.link === 'none' || project.disabled);
    const chips = [
        { label: 'Website' },
        { label: 'Wordpress' },
        { label: 'Front-end Dev' },
    ];

    return (
        <>
            <div className="web-design">
                <div className="project-header" id="web-design">
                    <div className="text-container">
                        <h3>DREAMSCAPE MARKETING</h3>
                        <h2>Web Design Projects</h2>
                        {chips && (
                            <div className="chip-container">
                                {chips.map((chip, index) => (
                                    <Chip label={chip.label} variant="outlined" />
                                ))}
                            </div>
                        )}
                    </div>
                    <div className="overlay"></div>
                </div>
                <div className="d-flex work-page">
                    <div className='web-design-container'>
                        <h4>Live Sites🎉</h4>
                        <p>Explore the live sites by clicking on the images.</p>
                        <div className="imgs-container">

                            {liveSites.map(project => (
                                <Link
                                    key={project.id}
                                    to={project.link}
                                    target="_blank"
                                    className={`web-design-img ${project.id}`}
                                    style={{
                                        backgroundImage: `url(${project.backgroundImage})`
                                    }}
                                ></Link>
                            ))}
                        </div>
                    </div>
                    <div className='web-design-container grey-bg'>
                        <h4>R.I.P.🪦</h4>
                        <div className="imgs-container">
                            {noLongerAvailable.map(project => (
                                <div
                                    key={project.id}
                                    className={`web-design-img disabled ${project.id}`}
                                    style={{
                                        backgroundImage: `url(${project.backgroundImage})`,
                                        filter: project.disabled ? 'grayscale(100%)' : 'none',
                                    }}
                                ></div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="project-button">
                    <div className="container">
                        <ProjectButton link={prevProjectLink} isNext={false} />
                        <ProjectButton link={nextProjectLink} isNext={true} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default WebDesign;
