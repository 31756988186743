// NttData.tsx
import React, { FC, useEffect, useState } from 'react';
import evmeHero from './../assets/evme/evme-hero.png';
import evmeProjectPlan from './../assets/evme/evme-gantt-chart.png';
import ca01 from './../assets/evme/competitive-analysis-01.png';
import ca02 from './../assets/evme/competitive-analysis-02.png';
import keyfinding01 from './../assets/evme/keyfinding01.png';
import keyfinding02 from './../assets/evme/keyfinding02.png';
import keyfinding03 from './../assets/evme/keyfinding03.png';
import keyfinding04 from './../assets/evme/keyfinding04.png';
import nttWireframe03 from './../assets/ntt/initiative-search-wireframe-01.png';
import nttWireframe04 from './../assets/ntt/initiative-search-wireframe-02.png';


import mockup01 from './../assets/evme/evme-mockup01.png';
import styleguide from './../assets/evme/styleguide.png';
import wireframe from './../assets/evme/wireframe.png';
import evmeStoryboards from './../assets/evme/evme-storyboard-etc.png';
import nttDocument from './../assets/ntt/mockup02.png';
import midFiPrototype from './../assets/evme/mid-fi-prototype.png';
import { Chip } from '@mui/material';
import { Parallax } from 'react-scroll-parallax';
import ProjectButton from './NextProject';
import CollapsibleWidget from './CollapsibleWidget';

interface EVMEProps {
    // Add your properties here
}
const EVME: FC<EVMEProps> = (props) => {
    const chips = [
        { label: 'Mobile App' },
        { label: 'UX Design' },
        { label: 'Branding' },
    ];

    const nextProjectLink = '/project-4';
    const prevProjectLink = '/project-1';

    const [parallaxSpeed, setParallaxSpeed] = useState(50);

    useEffect(() => {
        const handleResize = () => {
            const newSpeed = window.innerWidth < 601 ? 10 : 50; // Adjust the speed as needed
            setParallaxSpeed(newSpeed);

        };

        // Initial setup
        handleResize();

        // Event listener for window resize
        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    console.log(parallaxSpeed);

    return (
        <div className="projects">
            <div className='ntt-hero evme-hero main'>
                <div className='parallax-container'>
                    <Parallax speed={30}>
                        <img src={evmeHero} className="circle-back" />
                    </Parallax>
                </div>
            </div>
            <div className="hero-text">
                <h3>EVME</h3>
                <h2>EV Charging Station Locator App</h2>
                <p>Mobile Application for Electric Vehicles Charging Station Locator</p>
                {chips && (
                    <div className="chip-container">
                        {chips.map((chip, index) => (
                            <Chip label={chip.label} variant="outlined" key={index} />
                        ))}
                    </div>
                )}
            </div>

            <div className='role-container text-w'>
                <div className='role-contents'>
                    <h4>
                        Role
                    </h4>
                    <p>
                        Project Manager <br />
                        UX Researcher <br />
                        UX Designer <br />
                        Branding <br />
                    </p>
                </div>
                <div className='role-contents'>
                    <h4>
                        Team
                    </h4>
                    <p>
                        Solo Project
                    </p>
                </div>
                <div className='role-contents'>
                    <h4>
                        Platform
                    </h4>
                    <p>
                        Mobile Application<br />
                        CarPlay</p>
                </div>
            </div>
            <div className='text-container text-w'>
                <h2>
                    Introduction
                </h2>
                <p>
                    Locating EV charging stations is a challenge. To help EV owners and attract buyers, a mobile app has been developed, benefiting companies like Tesla, GM, and Nissan.</p>
                <p>
                    Limited charging options impact decisions, encouraging collaboration between EV sellers, charging stations, and the US Government.
                </p>
                <h2>
                    What Sets EVME Apart?
                </h2>
                <p>
                    My primary focus was on the potential integration of Electric Vehicle (EV) data into the app for automatic calculation of pricing and charging times based on individual car details. </p>

                <p>Exploring available APIs in the market, I identified sources offering essential information such as estimated charging time, battery levels, capacity, and charging status.                </p>

            </div>
            <div className='mockup blue-bg'>
                <Parallax
                    easing="easeInQuad"
                    speed={parallaxSpeed}>
                    <img src={mockup01} />
                </Parallax>

            </div>


            <div className='text-container text-w'>

                <h2>Project Planning</h2>
                <p>
                    The project was strategically planned over 8 weeks, utilizing the "Slick Gantt" plugin in Google Spreadsheets for a comprehensive chart with five phases and relevant subtasks. This plan guides stakeholders, sponsors, and teams, ensuring clear goals, risk mitigation, and timely product delivery.
                </p>
                <p className='annotation'>Gantt Chart</p>
                <img src={evmeProjectPlan} className='gantt-chart'/>
                <p>
                    Before getting into the nitty-gritty of a project, I start by skeching down ideas, doing some business research, making mind maps, and outlining a simple user flow. It's all about shaping and refining the project concept from the get-go.
                </p>
                <img src={evmeStoryboards} />

            </div>

            <div className='text-container text-w'>
                <h2>
                    Discovering Insights</h2>
                <p>
                    In the research phase, I utilized five methods to identify pain points and validate assumptions.
                </p>
                <CollapsibleWidget />

            </div>
            <div className='mockup green-bg'>
                <Parallax speed={parallaxSpeed}>
                    <img src={styleguide} />
                </Parallax>
            </div>
            <div className='text-container text-w'>
                <h2>
                    Design Process
                </h2>
                <h3>
                    Initial Wireframe
                </h3>
                <img src={wireframe} />
                <p>
                    I sketched out the basic structure of the product, mapping essential elements and interactions. This simple blueprint set the foundation for the next design steps.</p>
                <h3>
                    Mid-fidelity Prototype
                </h3>
                <img src={midFiPrototype} />
                <p>
                    Utilizing Figma for mid-fidelity wireframes and prototypes. At this stage, I designed the overall layout of pages with placeholder images and blurbs, testing usability before progressing to high-fidelity mockups.                </p>

                <h3>
                    Key Findings from Usability Testing
                </h3>
                <div className='key-finding-container'>
                    <img src={keyfinding01} />
                    <img src={keyfinding02} />
                    <img src={keyfinding03} />
                    <img src={keyfinding04} />
                </div>
                <h3>
                    Hi-fidelity Prototype
                </h3>
                <div className="prototype-row">
                    <div className="prototype mobile-prototype">
                        <h6>EVME Mobile App Prototype</h6>
                        <iframe
                            width="100%" height="600"
                            src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FX1XF6oycstAtuz3ZdpHDNJ%2FEVME-Mobile-App%3Fpage-id%3D2%253A5%26node-id%3D201%253A7154%26viewport%3D241%252C48%252C0.07%26scaling%3Dscale-down%26starting-point-node-id%3D201%253A7162"
                            allowFullScreen ></iframe>
                    </div>
                    <div className="prototype">
                        <h6>EVME Carplay Prototype</h6>
                        <iframe
                            width="100%" height="600"
                            src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FX1XF6oycstAtuz3ZdpHDNJ%2FEVME-Mobile-App%3Fpage-id%3D237%253A13499%26node-id%3D237%253A13512%26viewport%3D241%252C48%252C0.18%26scaling%3Dmin-zoom%26starting-point-node-id%3D237%253A13512"
                            allowFullScreen></iframe>
                    </div>
                </div>

            </div>

            <div className='text-container text-w'>
                <h2>
                    Next Steps
                </h2>
                <p>Feedback from presenting the high-fidelity prototype suggests a chance to expand research and add features allowing users to create paths or routes around charging stations.</p>
                <blockquote className="blockquote"> "Can I search and create a path/route around charge stations?"</blockquote>                <p>
                    I plan to conduct multiple user testing rounds on the final prototype to enhance our app's quality. Post-launch, gathering ongoing customer feedback and making appropriate system improvements will be our next steps.                </p>
            </div>

            <div className="project-button">
                <div className="container">
                    <ProjectButton link={prevProjectLink} isNext={false} />
                    <ProjectButton link={nextProjectLink} isNext={true} />
                </div>
            </div>
        </div>
    );
};

export default EVME;
