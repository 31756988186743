import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Work01 from './../../assets/images/archive/work01.jpg';
import Work02 from './../../assets/images/archive/work02.jpg';
import Work03 from './../../assets/images/archive/work03.png';
import Work04 from './../../assets/images/archive/work04.png';
import Work05 from './../../assets/images/archive/work05.png';
import Work06 from './../../assets/images/archive/work06.png';
import Work07 from './../../assets/images/archive/work07.jpg';
import Work08 from './../../assets/images/archive/work08.jpg';
import Work09 from './../../assets/images/archive/work09.jpg';
import Work10 from './../../assets/images/archive/work10.jpg';
import Work11 from './../../assets/images/archive/work11.png';
import Work12 from './../../assets/images/archive/work12.jpg';
import Work13 from './../../assets/images/archive/work13.jpg';
import Work14 from './../../assets/images/archive/work14.jpg';
import Work15 from './../../assets/images/archive/work15.jpg';
import Work16 from './../../assets/images/archive/work16.jpg';
import Work17 from './../../assets/images/archive/work17.jpg';
import Work18 from './../../assets/images/archive/work18.jpg';
import Work19 from './../../assets/images/archive/work19.png';
import Work20 from './../../assets/images/archive/work20.jpg';
import Work21 from './../../assets/images/archive/work21.jpg';
import Work22 from './../../assets/images/archive/work22.png';
import Work23 from './../../assets/images/archive/work23.png';
import Work24 from './../../assets/images/archive/work24.jpg';
import Work25 from './../../assets/images/archive/work25.jpg';
import Work26 from './../../assets/images/archive/work26.jpg';
import Work27 from './../../assets/images/archive/work27.jpg';
import Work28 from './../../assets/images/archive/work28.jpg';
import Work29 from './../../assets/images/archive/work29.jpg';
import Work30 from './../../assets/images/archive/work30.jpg';
import Scene from './../../assets/images/archive/Scene.png';
import Scene1 from './../../assets/images/archive/Scene-1.png';
import Scene2 from './../../assets/images/archive/Scene-2.png';
import Scene3 from './../../assets/images/archive/Scene-3.png';
import Scene4 from './../../assets/images/archive/Scene-4.png';
import Scene5 from './../../assets/images/archive/Scene-5.png';
import Scene6 from './../../assets/images/archive/Scene-6.png';
import Scene7 from './../../assets/images/archive/Scene-7.png';
import Scene8 from './../../assets/images/archive/Scene-8.png';
import Scene9 from './../../assets/images/archive/Scene-9.png';
// Function to shuffle an array
const shuffleArray = (array: { img: string; title: string; }[]) => {
  const shuffledArray = [...array];
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
};


const itemData = shuffleArray([
  {
    img: Work01,
    title: 'Work-1',
  },
  {
    img: Work02,
    title: 'Work-2',
  },
  {
    img: Work03,
    title: 'Work-3',
  },
  {
    img: Work04,
    title: 'Work-4',
  },
  {
    img: Work05,
    title: 'Work-5',
  },
  {
    img: Work06,
    title: 'Work-6',
  },
  {
    img: Work07,
    title: 'Work-7',
  },
  {
    img: Work08,
    title: 'Work-8',
  },
  {
    img: Work09,
    title: 'Work-9',
  },
  {
    img: Work10,
    title: 'Work-10',
  },
  {
    img: Work11,
    title: 'Work-11',
  },
  {
    img: Work12,
    title: 'Work-12',
  },
  {
    img: Work13,
    title: 'Work-13',
  },
  {
    img: Work14,
    title: 'Work-14',
  },
  {
    img: Work15,
    title: 'Work-15',
  },
  {
    img: Work20,
    title: 'Work-20',
  },
  {
    img: Work22,
    title: 'Work-22',
  },
  {
    img: Work25,
    title: 'Work-25',
  },
  {
    img: Work26,
    title: 'Work-26',
  },
  {
    img: Work27,
    title: 'Work-27',
  },
  {
    img: Work28,
    title: 'Work-28',
  },
  {
    img: Work29,
    title: 'Work-29',
  },
  {
    img: Work30,
    title: 'Work-30',
  },


  {
    img: Scene,
    title: 'Scene',
  },
  {
    img: Scene1,
    title: 'Scene1',
  },
  {
    img: Scene2,
    title: 'Scene2',
  },
  {
    img: Scene3,
    title: 'Scene3',
  },
  {
    img: Scene4,
    title: 'Scene4',
  },
  {
    img: Scene5,
    title: 'Scene5',
  },
  {
    img: Scene6,
    title: 'Scene6',
  },
  {
    img: Scene7,
    title: 'Scene7',
  },
  {
    img: Scene8,
    title: 'Scene8',
  },
  {
    img: Scene9,
    title: 'Scene9',
  },
]);

export default function MasonryImageList() {
  return (
    <>
      <div className="project-header" id="archive">
        <div className="text-container">
          <h2>Archive 🎨</h2>
          <p>
            Explore a diverse collection of art & design projects, including drawings, paintings, logos, brand guides, books, posters, infographics, flyers, web designs, and playbooks. Each piece showcases my creativity and commitment to crafting visually impactful designs.
          </p></div>
        <div className="overlay"></div>
      </div>
      <ImageList variant="masonry" cols={3} gap={8}>
        {itemData.map((item) => (
          <ImageListItem key={item.img}>
            <img
              srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
              src={`${item.img}?w=248&fit=crop&auto=format`}
              alt={item.title}
              loading="lazy"
            />
          </ImageListItem>
        ))}
      </ImageList>
    </>
  );
}